import React from 'react'

const Logo = () => (
  <svg viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect fill="#E5E5E5"/>
    <rect transform="translate(-22 -249)" fill="white"/>
    <path d="M31.1842 58C30.8626 58 30.5848 57.883 30.3509 57.6491C30.117 57.4152 30 57.1374 30 56.8158V49.1842C30 48.8626 30.117 48.5848 30.3509 48.3509C30.5848 48.117 30.8626 48 31.1842 48H38.8158C39.1374 48 39.4152 48.117 39.6491 48.3509C39.883 48.5848 40 48.8626 40 49.1842V56.8158C40 57.1374 39.883 57.4152 39.6491 57.6491C39.4152 57.883 39.1374 58 38.8158 58H31.1842Z" fill="black"/>
    <path d="M12.7022 63C12.2399 63 11.8406 62.8308 11.5043 62.4923C11.1681 62.1538 11 61.7519 11 61.2865V31.7135C11 31.2481 11.1681 30.8462 11.5043 30.5077C11.8406 30.1692 12.2399 30 12.7022 30H23.7348C24.1971 30 24.5964 30.1692 24.9326 30.5077C25.2688 30.8462 25.437 31.2481 25.437 31.7135V33.9346C26.8239 32.7077 28.358 31.7558 30.0391 31.0788C31.7203 30.3596 33.4645 30 35.2717 30H38.2978C38.7601 30 39.1594 30.1692 39.4957 30.5077C39.8319 30.8462 40 31.2481 40 31.7135V41.6135C40 42.0788 39.8319 42.4808 39.4957 42.8192C39.1594 43.1577 38.7601 43.3269 38.2978 43.3269H30.1652C27.6435 43.3269 26.3826 44.5962 26.3826 47.1346V61.2865C26.3826 61.7519 26.2145 62.1538 25.8783 62.4923C25.542 62.8308 25.1428 63 24.6804 63H12.7022Z" fill="black"/>
    <path d="M14.0319 79C13.2084 79 12.4972 78.8231 11.8983 78.4694C11.2994 78.1156 11 77.6956 11 77.2092V67.7908C11 67.3044 11.2994 66.8844 11.8983 66.5306C12.4972 66.1769 13.2084 66 14.0319 66H81.9681C82.7916 66 83.5028 66.1769 84.1017 66.5306C84.7006 66.8844 85 67.3044 85 67.7908V77.2092C85 77.6956 84.7006 78.1156 84.1017 78.4694C83.5028 78.8231 82.7916 79 81.9681 79H14.0319Z" fill="black"/>
    <path d="M63.9047 63C59.4145 63 55.6021 62.3824 52.4675 61.1472C49.3752 59.912 47.0454 58.2722 45.4781 56.2278C43.9107 54.1407 43.0847 51.862 43 49.3917C43 49.0083 43.1271 48.6889 43.3812 48.4333C43.6778 48.1352 44.0166 47.9861 44.3979 47.9861H56.4705C57.0635 47.9861 57.5295 48.0713 57.8684 48.2417C58.2496 48.3694 58.6944 48.6463 59.2027 49.0722C60.4312 50.0519 61.9985 50.5417 63.9047 50.5417C65.3449 50.5417 66.4887 50.3926 67.3359 50.0944C68.2254 49.7537 68.6702 49.3065 68.6702 48.7528C68.6702 47.9861 68.0983 47.4111 66.9546 47.0278C65.8109 46.6019 63.6505 46.1759 60.4735 45.75C49.5446 44.3019 44.0802 39.6167 44.0802 31.6944C44.0802 28.8407 44.885 26.3065 46.4947 24.0917C48.1467 21.8343 50.4342 20.088 53.357 18.8528C56.2799 17.6176 59.6475 17 63.4599 17C67.4418 17 70.9365 17.6815 73.944 19.0444C76.9516 20.4074 79.2602 22.1324 80.8699 24.2194C82.4796 26.2639 83.3268 28.2657 83.4115 30.225C83.4115 30.6083 83.2632 30.9491 82.9667 31.2472C82.7126 31.5028 82.416 31.6306 82.0772 31.6306H69.3691C68.7761 31.6306 68.3101 31.5667 67.9713 31.4389C67.6324 31.2685 67.2511 30.9917 66.8275 30.6083C65.938 29.8417 64.8154 29.4583 63.4599 29.4583C61.4266 29.4583 60.41 30.0546 60.41 31.2472C60.41 31.8861 60.8971 32.4185 61.8714 32.8444C62.888 33.2704 64.7519 33.6537 67.4629 33.9944C71.6989 34.5481 75.0877 35.3787 77.6293 36.4861C80.2133 37.5935 82.0772 39.0843 83.2209 40.9583C84.407 42.7898 85 45.1111 85 47.9222C85 50.9463 84.0893 53.6083 82.2678 55.9083C80.4463 58.1657 77.9259 59.912 74.7065 61.1472C71.5295 62.3824 67.9289 63 63.9047 63Z" fill="black"/>
  </svg>
)

export default Logo
